body {
  margin: 0;
  padding: 0;
}
/*wap*/
.wap{position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 999;
opacity: 80%;}


  /*EXPERIENCIA*/

.experiencia{
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 5%;
}


/*NAVBAR*/
.dropdown-menu li {
  position: relative;
  }
  .dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
  }
  .dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
  }
  .dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
  }

.navbar-brand{width: 15%;}
.navbar {
  background-color: #FFFFFF !important;
  box-shadow: 0 0 4px grey;
  transition: background-color 0.5s ease 0s;
  font-size: larger;
  font-weight: 400;
}

.nav-link {
  color: #1A67B1 !important;
  transition: color 1s ease 0s;
  text-decoration: none;
}

.link:hover {
  color: #009DC6 !important;
}

.navbar.solid {
  background-color: transparent !important;
  transition: background-color 0.5s ease 0s;
  box-shadow: none;
  border-bottom: white !important;
}
.navbar-toggler.solid3{ border: 2px solid white !important;}
.navbar-toggler-icon.solid2{
 
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.7%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%271.8%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.nav-link.color {
  color: #FFFFFF !important;
  transition: color 1s ease 0s;
}

/*HOME*/

#img {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(./Components/Home/img/Iniciofinal2.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  margin: auto auto;
}


#descripcion {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
}

#descripcion2 {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  text-align:end;
}

.experiencia {
  margin-top: 8%;
  opacity: 0.75;
}

/* FORM HOME */
.formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formbold-form-wrapper {
  margin: 0 auto;
  max-width: 550px;
  width: 100%;
  background: white;
}

.formbold-input-flex {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.formbold-input-flex>div {
  width: 50%;
}

.formbold-input-radio-wrapper {
  margin-bottom: 28px;
}

.formbold-radio-flex {
  display: flex;
  align-items: center;
  gap: 15px;
}

.formbold-radio-label {
  font-size: 14px;
  line-height: 24px;
  color: #07074D;
  position: relative;
  padding-left: 1vh;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.formbold-input-radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.formbold-radio-checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #FFFFFF;
  border: 1px solid #DDE3EC;
  border-radius: 50%;
}


.formbold-radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.formbold-radio-label .formbold-input-radio:checked~.formbold-radio-checkmark:after {
  display: block;
}



.formbold-form-input {
  width: 100%;
  padding: 13px 22px;
  border-radius: 5px;
  border: 1px solid #DDE3EC;
  background: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
  color: #07074D;
  outline: none;
  resize: none;
}

.formbold-form-input::placeholder {
  color: #536387;
}

.formbold-form-input:focus {
  border-color: rgb(16, 49, 84);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-form-label {
  color: #07074D;
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
}

.formbold-btn {
  text-align: center;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  padding: 14px 25px;
  border: none;
  font-weight: 500;
  background-color: rgb(0, 134, 179);
  color: white;
  cursor: pointer;
  margin-top: 25px;
}

.formbold-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

/*MODELOS*/
#border{border-radius: 0.5rem; border-color: rgb(0, 134, 179)!important;}
h4.card-title.text-center{color: #1B407E;font-weight: 700;}




/*COBERTORES Y CERCOS*/
#img2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(./Components/Navbar/Img/Cobertor2.1.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
  margin: auto auto;
  background-position: center;
}

#img2-1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(./Components/Navbar/Img/cobertorbeige2.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-position: center;
}

#descripcioncobertor {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  text-align: start;
}

#titulocobertor {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin-top: 10vh;
}

#img3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(./Components/Navbar/Img/cercogris.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;background-position: center;
}

#img3-1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(./Components/Navbar/Img/cerconegro.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;background-position: center;
}

/*ILUMINACION*/

#imgiluminacion {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(./Components/Navbar/Img/IluminacionFinal.jpg);
  background-size: cover;
  height: 110vh;
  width: 100%;background-position: center;
}

#imgiluminacion2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(./Components/Navbar/Img/Iluminacion2.1.jpg);
  background-size: cover;
  height: 110vh;
  width: 100%;background-position: center;
}

#tituloiluminacion {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin-top: 10vh;
}

#descripcioniluminacion {
  font-weight: 400;
  text-align: start;
  color: rgba(255, 255, 255, 0.9);
}
.descripcionmobile{font-size: x-small;text-align: justify;
font-weight: 500;}

/*ROBOTS*/
.body-robots{font-size: medium;}
.card-title.robot{color: #1A67B1; font-size: x-large;}


/*TERMOTANQUE SOLAR*/
#termotanquesolar{background-image:linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
  url(./Components/Navbar/Img/termotanque.jpg) !important;background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  margin: auto auto;}
  .termotanque{margin-top: 20%;}




/*CLIMATIZACION SOLAR*/
#imgsolar {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(./Components/Navbar/Img/solar1.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-position: center;
}

#imgsolar2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(./Components/Navbar/Img/solar2.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-position: center;
}

#titulosolar {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin-top: 15vh;
  
}
#alineardescripcion{
  margin-left: 10%;
  margin-right: 10%;
}

#descripcionsolar {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
}

/*RIEGOS*/
.overflow{  overflow-x: hidden;}

#imgriego {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(./Components/Navbar/Img/riego1.jpg);
  background-size: cover;
  height: 100vh;
  width: 100vw;
  background-position: center;
}

#imgriego2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(./Components/Navbar/Img/riego2.jpg);
  background-size: cover;
  height: 100vh;
  width: 100vw;background-position: center;
}

#imgriego3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(./Components/Navbar/Img/riego3.jpg);
  background-size: cover;
  height: 100vh;
  width: 100vw;background-position: center;
}

#tituloriego {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin-top: 5vh;
}

#descripcionriego {
  font-weight: 400;
  text-align: start;
  color: rgba(255, 255, 255, 0.9);
}

/* PRODUCTOS */
    .textoproductos{color: #1A67B1 !important;margin-top: 2rem;}
    .custom-link {color: #1A67B1 !important;
      display: block;
      text-decoration: none;
      color: #007bff; /* Color del texto del enlace */
      background-color: #e0f7fa; /* Color de fondo del enlace */
      padding: 10px; /* Espaciado interno */
      border-radius: 3px; /* Bordes redondeados */
      text-align: center; /* Centra el texto */
    }
    
    .custom-link:hover {
      background-color: #b2ebf2; /* Cambio de color de fondo al pasar el ratón */
      color: #0056b3; /* Cambio de color del texto al pasar el ratón */
    }

    /*CALCULADORA PRODUCTOS*/
    .texto-calculadora{color: #1A67B1;}
    
    




@media (min-width: 1200px){

  /*HOME y RIEGO*/
  #descripcion,#tituloriego{font-size: xx-large;}
  #descripcion2,#descripcionriego{font-size: x-large;}

  /*ILUMINACION y COBERTORES*/
  #tituloiluminacion,#titulocobertor{font-size:xx-large}
 #descripcioniluminacion,#descripcioncobertor{font-size: x-large;}

 /*SOLAR y ROBOT*/
 #titulosolar,#titulorobot{font-size: xx-large;}
#descripcionsolar,#descripcionrobot{font-size: x-large;}
}


/*@MEDIA PANTALLA MEDIA*/
@media (min-width: 768px) and (max-width: 991.9px){
   /*NAVBAR*/
   .navbar-brand{width: 25%;}
   .nav-link.color {background-color: #343A40 !important;padding-left: 1rem;}
}
@media (min-width: 768px) and (max-width: 1199px) {
/*PRODUCTOS*/

.productos2{width: 100%;}


 

  /*HOME*/
  #descripcion{font-size: x-large;}
  #descripcion2{font-size: large;}
  .experiencia{width: 15%!important;}

  /*IMAGEN SOLAR 1 Y 3 QUEDA NORMAL */
  #imgsolar3 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
      url(./Components/Navbar/Img/768-1199/solar3-768x1199.png);background-position: center;
  }
  #titulosolar{font-size: x-large;}
#descripcionsolar{font-size: large;}

  /*IMAGENES ILUMINACION*/
  #imgiluminacion {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
      url(./Components/Navbar/Img/768-1199/Iluminacion/1iluminacion768x1199.png);background-position: center;
  }

  #imgiluminacion2 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
      url(./Components/Navbar/Img/768-1199/Iluminacion/iluminacion2.1-768x1199.png);background-position: center;
  }

  #tituloiluminacion{font-size:x-large}
 #descripcioniluminacion{font-size: large;}

 
  /*IMAGENES CERCOS*/
  #img3{background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/768-1199/Cercos/cercogris-768x1199.png);background-position: center;}
#img3-1{background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
  url(./Components/Navbar/Img/768-1199/Cercos/cerconegro2-768x1199.png);background-position: center;}

  /*IMAGENES RIEGOS*/
#imgriego2{background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
  url(./Components/Navbar/Img/768-1199/Riego/riego2-768x1199.png) !important;background-position: center;}
  #imgriego3{background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/768-1199/Riego/riego3-768x1199.png)!important;background-position: center;}
  }

/*@MEDIA PANTALLA CELULAR VERTICAL*/
@media (max-width: 480px) {
  /*WAP*/
  .wap{width: 20%;}
  /*NAVBAR*/
  .navbar-brand{width: 40%;}
  .nav-link.color {background-color: #343A40 !important;padding-left: 1rem;}

  /*PRODUCTOS*/
 
  .textoproductos{font-size: large;}

 

  /*HOME*/
  #descripcion{font-size: xx-large;margin-top:5vh;}
  #descripcion2{font-size: small; margin-top: 10vh;text-align: justify;
    text-align-last: start; word-spacing: 0.01em;}
  .experiencia{width: 20%!important;
    margin-top: 20%;}
    #containerformizq{border-bottom: solid;border-width: thin;}
    #containerformder{margin-top: 1vh;}
    .formbold-form-input{font-size: small;}

    /*TERMOTANQUE*/
    #termotanqueimg{width: 100% !important;}
    #termotanque{width: 100%;}
    .termotanque{margin-top: 10%;text-align: center;}
    .termotanquetitulo{font-size: large;}
    #termotanquesolar{background-image:linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
      url(./Components/Navbar/Img/481-767/Solar/termotanque.jpg) !important; margin-top: -15%;}
    

  /*IMAGENES RIEGO*/
  #tituloriego{font-size: xx-large;}
  #imgriego{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/max-480/Riego/riego1-max480.png) !important;background-position: center;
  }
#imgriego2{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
  url(./Components/Navbar/Img/max-480/Riego/riego2-max480.png)!important;
   background-position: center;
}
#imgriego3{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/max-480/Riego/riego3-max480.png) !important;background-position: center;
}


/*IMAGENES ROBOT y BOMBAS*/
.body-robots{font-size: small;}
.principalrow{margin: 0!important;}

/*IMAGENES BOMBAS*/


  /*IMAGENES CERCO Y COBERTOR*/

  #img2{background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/max-480/Cobertor/cobertor1-max480.png);background-position: center;}
  #img2-1{background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/max-480/Cobertor/cobertorbeige-max480.png);background-position: center;}
  #img3{background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/max-480/Cerco/cercogris-max480.png);background-position: center;}
  #img3-1{background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/max-480/Cerco/cerconegro-max480.png);background-position: center;}
  
    #titulocobertor{font-size: x-large;}
  #descripcioncobertor{font-size: medium;}

    /*IMAGENES ILUMINACION*/
  #imgiluminacion{background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/max-480/Iluminacion/Iluminacion1-max480.png)!important;background-position: center;}
  #imgiluminacion2{background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/max-480/Iluminacion/Iluminacion2.1-max480.png);background-position: center;}
  #tituloiluminacion{font-size:x-large}
  #descripcioniluminacion{font-size: medium;}
  .descripcionmobile{font-size: large;}

    /*IMAGENES SOLAR*/
    #imgsolar3{background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
      url(./Components/Navbar/Img/max-480/Solar/Solar3-max480.png);background-position: center;
    }
    #titulosolar{font-size: x-large;}
#descripcionsolar{font-size: medium;}

} 


/*@MEDIA PANTALLA TABLET, CELULAR HORIZONTAL*/

@media(min-width: 481px) and (max-width: 768px) { 
   /*NAVBAR*/
   .navbar-brand{width: 40%;}
   .nav-link.color {background-color: #343A40 !important;padding-left: 1rem;}
   
  /*PRODUCTOS*/
  .textoproductos{font-size: x-large;}
  .imagenproductos{width: 80%!important;}
  .divimagen{justify-content: center;display: flex;padding: 3rem;}
  


  /*HOME*/
  #descripcion{font-size: x-large;}
  #descripcion2{font-size: medium;margin-top: 10vh;text-align: justify;
    text-align-last: start; word-spacing: 0.01em;}
  .experiencia{width: 20%!important;margin-top: 15%;}

  /*TERMOTANQUE*/

#termotanquesolar{background-image:linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
      url(./Components/Navbar/Img/768-1199/solar3-768x1199.png) !important; }
      
  /*IMAGENES RIEGO */
  
  #imgriego2{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/481-767/Riego/riego2-480.jpg) !important;background-position: center;
 }
 #imgriego3{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/481-767/Riego/riego3-481-767.jpg) !important;background-position: center;
 }
 /*IMAGENES COBERTORES Y CERCOS*/
 #titulocobertor{font-size: xx-large!important;}
 #descripcioncobertor{font-size: larger!important;}
 #img2{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/481-767/Cobertor/cobertor-481-767.jpg);background-position: center;
 }
 #img2-1{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/481-767/Cobertor/cobertorbeige-481-767.jpg);background-position: center;
 }
 #img3{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
  url(./Components/Navbar/Img/481-767/Cercos/cercogris-481-767.jpg);background-position: center;
 }
 #img3-1{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/481-767/Cercos/cerconegro-481-767.jpg);background-position: center;
 }
 /* IMAGENES ILUMINACION */
 #imgiluminacion{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/481-767/Iluminacion/Iluminacion1-481-767.jpg)!important;background-position: center;
 }
 #imgiluminacion2{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url(./Components/Navbar/Img/481-767/Iluminacion/iluminacion2.1-481-767.jpg);background-position: center;
 }
 #tituloiluminacion{font-size:x-large}
 #descripcioniluminacion{font-size: large;}

 /*IMAGENES SOLAR*/
#titulosolar{font-size: x-large;}
#descripcionsolar{font-size: large;}

    /*IMAGENES ROBOT*/
    .principalrow{margin: 0!important;}
}